















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import progressBar from '@/components/form/progress-bar.vue';
import FormCheckbox from '@/components/form/form-checkbox.vue';
import popUpModal from '@/components/form/pop-up-modal.vue';
import FormSwitch from '@/components/form/form-switch.vue';
import VehicleTypes from '@/config/VehicleTypes';
import CountriesList from '@/components/form/countries-list.vue';
import errorMessage from '@/components/form/error-message.vue';
import COUNTRIES from '@/config/countires-list';
import { CASE_STATUS, WEBSITE_URL } from '@/constant/constants';
import { api } from '@/service/instance';
// import priceCalculator from '@/service/price-calculator';

const counrtyItem = {
  country: '',
  isSelected: false,
};
const vehicleList = {
  Kennzeichen: '',
  Fahrzeugart: '',
  Gesamtgewicht: '',
  isSelected: false,
};

const counrtyType = 1;
const vehicleListType = 2;
export default Vue.extend({
  name: 'road-haulier',
  components: {
    progressBar,
    popUpModal,
    FormSwitch,
    FormCheckbox,
    vSelect,
    'countries-list': CountriesList,
    errorMessage,
  },
  props: ['assesment', 'insuranceKey', 'caseStatus', 'progressBar', 'id', 'formData', 'isAPIObserver', 'AMSAPISendStatus'],
  data() {
    return {
      TimoCom: 0,
      LoadFox: 0,
      Trans: 0,
      Cargoclix: 0,
      BulkExchange: 0,
      Sonstige: 0,
      SonstigeText: '',
      thirdPartyContainers: 0,
      thirdPartyTrailers: 0,
      Others: false,
      OthersText: '',
      AGB_449: false,
      ADSp: false,
      AGB_BSK: false,
      AGBremovals: false,
      VBGL: false,
      VehicleTypes,
      VehicleType: '',
      processName: '',
      transportInsuranceForClients: false,
      key: 'LOGISTICS_INSURANCE',
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      AGB: false,
      AGBError: false,
      grossAnnual: 0,
      grossAnnualError: false,
      regionalUpTo150Percent: 0,
      regionalUpTo150: 0,
      DeutschlandPercentage: 0,
      Deutschland: 0,
      BorderingCountriesPercentage: 0,
      BorderingCountries: 0,
      EuropePercentage: 0,
      Europe: 0,
      SumPercentage: 0,
      SumPercentageError: false,
      GeneralFreight: 0,
      ChilledGoods: 0,
      FrozenFood: false,
      TemperatureControlled: 0,
      over100k: false,
      HighQuality: 0,
      over300k: false,
      CEPServices: 0,
      Bulk: 0,
      MovingGoods: 0,
      HeavyLift: 0,
      GoodsInTank: 0,
      MotorVehiclesOfAllKinds: 0,
      SumSpecialTypes: 0,
      SumSpecialTypesError: false,
      foreignUnitsPromoted: false,
      foreignUnitsPromotedError: false,
      subcontractorsUsed: false,
      VehicleFleet: false,
      subcontractorsViaFreight: false,
      AGBinsuranceFile: {
        originalName: '',
        name: '',
        fileUploaded: false,
        uploadPercentage: 0 as number,
      },
      FahrzeuglisteinsuranceFile: {
        originalName: '',
        name: '',
        fileUploaded: false,
        uploadPercentage: 0 as number,
      },
      insuranceFile: {
        name: '',
        base64: '',
        extension: '',
      },
      counrtyItems: [
        {
          ...counrtyItem,
        },
      ],
      vehicleListTypes: [
        {
          ...vehicleList,
        },
      ],
      mainSubjectError: false,
      mainPriceError: false,
      bycounrtyType: counrtyType,
      byvehicleListType: vehicleListType,
      Gesamtgewicht: [
        'bitte Auswahl treffen',
        '<= 3,5 to.',
        '<= 7,5 to.',
        '<= 12 to.',
        '> 12 to.',
      ],
      subcontractorsViaFreightError: false,
    };
  },
  watch: {
    grossAnnual: 'calculateSales',
    regionalUpTo150Percent: 'calculateSales',
    DeutschlandPercentage: 'calculateSales',
    BorderingCountriesPercentage: 'calculateSales',
    EuropePercentage: 'calculateSales',
    GeneralFreight: 'calculateSumSpecialTypes',
    ChilledGoods: 'calculateSumSpecialTypes',
    TemperatureControlled: 'calculateSumSpecialTypes',
    HighQuality: 'calculateSumSpecialTypes',
    CEPServices: 'calculateSumSpecialTypes',
    Bulk: 'calculateSumSpecialTypes',
    MovingGoods: 'calculateSumSpecialTypes',
    HeavyLift: 'calculateSumSpecialTypes',
    GoodsInTank: 'calculateSumSpecialTypes',
    MotorVehiclesOfAllKinds: 'calculateSumSpecialTypes',
  },
  methods: {
    onFileChange(event: any) {
      const labelFileMame = event.target.attributes.name.value;
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        switch (labelFileMame) {
          case 'AGBinsuranceFile':
            this.AGBinsuranceFile.fileUploaded = false;
            this.AGBinsuranceFile.uploadPercentage = 0;
            this.AGBinsuranceFile.originalName = event.target.files[0].name;
            break;
          case 'FahrzeuglisteinsuranceFile':
            this.FahrzeuglisteinsuranceFile.fileUploaded = false;
            this.FahrzeuglisteinsuranceFile.uploadPercentage = 0;
            this.FahrzeuglisteinsuranceFile.originalName = event.target.files[0].name;
            break;
          default:
            // console.log('default');
            break;
        }
        this.insuranceFile.name = event.target.files[0].name;
        this.insuranceFile.base64 = reader.result!.toString();
        this.insuranceFile.extension = event.target.files[0].name.substr(
          event.target.files[0].name.lastIndexOf('.') + 1,
        );
      });
      reader.readAsDataURL(event.target.files[0]);
    },
    uploadFileToServer(name: string) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const data = {
        id: this.id,
        path: 'road-haulier',
        // eslint-disable-next-line @typescript-eslint/camelcase
        investment_file: {
          ...this.insuranceFile,
        },
      };
      api.post('upload_logistics_file', data, {
        baseURL: WEBSITE_URL,
        onUploadProgress(progressEvent: any) {
          that.fileUploadPercent(name, (progressEvent.loaded / progressEvent.total) * 100);
          // that.uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
        },
      }).then((result) => {
        // this.fileUploaded = true;
        this.fileUploadName(result.data.data, name, true);
      }).catch((error) => {
        this.fileUploadName('', name, false);
      });
    },
    fileUploadName(name: string, modelName: string, status: boolean) {
      switch (modelName) {
        case 'AGBinsuranceFile':
          this.AGBinsuranceFile.name = name;
          this.AGBinsuranceFile.fileUploaded = status;
          break;
        case 'FahrzeuglisteinsuranceFile':
          this.FahrzeuglisteinsuranceFile.name = name;
          this.FahrzeuglisteinsuranceFile.fileUploaded = status;
          break;
        default:
          console.log('default');
          break;
      }
    },
    fileUploadPercent(name: string, value: number) {
      switch (name) {
        case 'AGBinsuranceFile':
          this.AGBinsuranceFile.uploadPercentage = value;
          break;
        case 'FahrzeuglisteinsuranceFile':
          this.FahrzeuglisteinsuranceFile.uploadPercentage = value;
          break;
        default:
          break;
      }
    },
    valueNotNull(val: any) {
      if (val === '') {
        return 0;
      }
      return val;
    },
    valueMore100(val: any) {
      if (val === '') {
        return 0;
      }
      if (val > 100) {
        return 100;
      }
      return val;
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    calculateSumSpecialTypes() {
      this.GeneralFreight = this.valueMore100(this.GeneralFreight);
      this.ChilledGoods = this.valueMore100(this.ChilledGoods);
      this.TemperatureControlled = this.valueMore100(this.TemperatureControlled);
      this.HighQuality = this.valueMore100(this.HighQuality);
      this.CEPServices = this.valueMore100(this.CEPServices);
      this.Bulk = this.valueMore100(this.Bulk);
      this.MovingGoods = this.valueMore100(this.MovingGoods);
      this.HeavyLift = this.valueMore100(this.HeavyLift);
      this.GoodsInTank = this.valueMore100(this.GoodsInTank);
      this.MotorVehiclesOfAllKinds = this.valueMore100(this.MotorVehiclesOfAllKinds);

      this.SumSpecialTypes = this.GeneralFreight + this.ChilledGoods + this.TemperatureControlled + this.HighQuality + this.CEPServices + this.Bulk + this.MovingGoods + this.HeavyLift + this.GoodsInTank + this.MotorVehiclesOfAllKinds;
    },
    calculateSales() {
      this.regionalUpTo150Percent = this.valueMore100(this.regionalUpTo150Percent);
      this.DeutschlandPercentage = this.valueMore100(this.DeutschlandPercentage);
      this.BorderingCountriesPercentage = this.valueMore100(this.BorderingCountriesPercentage);
      this.EuropePercentage = this.valueMore100(this.EuropePercentage);

      this.regionalUpTo150 = (this.valueNotNull(this.regionalUpTo150Percent) * this.grossAnnual) / 100;
      this.Deutschland = (this.valueNotNull(this.DeutschlandPercentage) * this.grossAnnual) / 100;
      this.BorderingCountries = (this.valueNotNull(this.BorderingCountriesPercentage) * this.grossAnnual) / 100;
      this.Europe = (this.valueNotNull(this.EuropePercentage) * this.grossAnnual) / 100;

      this.SumPercentage = this.regionalUpTo150Percent + this.DeutschlandPercentage + this.BorderingCountriesPercentage + this.EuropePercentage;
    },
    dublicateRow(fillData: boolean, type: number) {
      let item;
      switch (type) {
        case this.byvehicleListType:
          item = JSON.parse(JSON.stringify(vehicleList));
          if (fillData && this.vehicleListTypes.length > 0) {
            item = JSON.parse(JSON.stringify(this.vehicleListTypes[this.vehicleListTypes.length - 1]));
          }
          this.vehicleListTypes.push({
            ...item,
          });
          break;
        case this.bycounrtyType:
          item = JSON.parse(JSON.stringify(counrtyItem));
          if (fillData && this.counrtyItems.length > 0) {
            item = JSON.parse(JSON.stringify(this.counrtyItems[this.counrtyItems.length - 1]));
          }
          this.counrtyItems.push({
            ...item,
          });
          break;
        default:
          console.log('Wrong type');
      }
    },
    deleteRows(type: number) {
      let items;
      switch (type) {
        case this.bycounrtyType:
          items = this.counrtyItems.filter((item) => !item.isSelected);
          this.counrtyItems = items;
          break;
        default:
          console.log('Wrong type');
      }
    },
    setValues(formData: any) {
      this.TimoCom = formData.TimoCom;
      this.LoadFox = formData.LoadFox;
      this.Trans = formData.Trans;
      this.Cargoclix = formData.Cargoclix;
      this.BulkExchange = formData.BulkExchange;
      this.Sonstige = formData.Sonstige;
      this.SonstigeText = formData.SonstigeText;
      this.thirdPartyContainers = formData.thirdPartyContainers;
      this.thirdPartyTrailers = formData.thirdPartyTrailers;
      this.Others = formData.Others;
      this.OthersText = formData.Others;
      this.AGB_449 = formData.AGB_449;
      this.ADSp = formData.ADSp;
      this.AGB_BSK = formData.AGB_BSK;
      this.AGBremovals = formData.AGBremovals;
      this.VBGL = formData.VBGL;
      this.VehicleType = formData.VehicleType;
      this.processName = formData.processName;
      this.transportInsuranceForClients = formData.transportInsuranceForClients;
      this.AGB = formData.AGB;
      this.grossAnnual = formData.grossAnnual;
      this.regionalUpTo150Percent = formData.regionalUpTo150Percent;
      this.regionalUpTo150 = formData.regionalUpTo150;
      this.DeutschlandPercentage = formData.DeutschlandPercentage;
      this.Deutschland = formData.Deutschland;
      this.BorderingCountriesPercentage = formData.BorderingCountriesPercentage;
      this.BorderingCountries = formData.BorderingCountries;
      this.EuropePercentage = formData.EuropePercentage;
      this.Europe = formData.Europe;
      this.SumPercentage = formData.SumPercentage;
      this.GeneralFreight = formData.GeneralFreight;
      this.ChilledGoods = formData.ChilledGoods;
      this.FrozenFood = formData.FrozenFood;
      this.TemperatureControlled = formData.TemperatureControlled;
      this.over100k = formData.over100k;
      this.HighQuality = formData.HighQuality;
      this.over300k = formData.over300k;
      this.CEPServices = formData.CEPServices;
      this.Bulk = formData.Bulk;
      this.MovingGoods = formData.MovingGoods;
      this.HeavyLift = formData.HeavyLift;
      this.GoodsInTank = formData.GoodsInTank;
      this.MotorVehiclesOfAllKinds = formData.MotorVehiclesOfAllKinds;
      this.SumSpecialTypes = formData.SumSpecialTypes;
      this.foreignUnitsPromoted = formData.foreignUnitsPromoted;
      this.subcontractorsUsed = formData.subcontractorsUsed;
      this.VehicleFleet = formData.VehicleFleet;
      this.subcontractorsViaFreight = formData.subcontractorsViaFreight;
      this.vehicleListTypes = formData.vehicleListTypes;
      this.AGBinsuranceFile = formData.AGBinsuranceFile;
      this.FahrzeuglisteinsuranceFile = formData.FahrzeuglisteinsuranceFile;
      this.counrtyItems = formData.counrtyItems;
    },
    goToBackUrl() {
      this.$router.push({
        name: this.backPath,
      }).catch((err: any) => {
        throw new Error(`Problem handling something: ${err}.`);
      });
    },
    validations() {
      if ((!this.AGB_449 && !this.ADSp && !this.AGB_BSK && !this.AGBremovals && !this.VBGL && !this.Others) && this.AGB) {
        this.AGBError = true;
        return false;
      }
      if (this.grossAnnual <= 0) {
        this.grossAnnualError = true;
        return false;
      }
      if (this.SumPercentage !== 100) {
        this.SumPercentageError = true;
        return false;
      }
      if (this.SumSpecialTypes !== 100) {
        this.SumSpecialTypesError = true;
        return false;
      }
      if (this.thirdPartyTrailers <= 0 && this.thirdPartyContainers <= 0 && this.foreignUnitsPromoted) {
        this.foreignUnitsPromotedError = true;
        return false;
      }
      if (this.TimoCom === 0 && this.LoadFox === 0 && this.Trans === 0 && this.Cargoclix === 0 && this.BulkExchange === 0 && this.Sonstige === 0 && this.subcontractorsViaFreight) {
        this.subcontractorsViaFreightError = true;
        return false;
      }
      return true;
    },
    scrollToFirstErrorElement() {
      const el = this.$el.getElementsByClassName('border-danger')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
    onSubmit() {
      if (!this.validations()) {
        setTimeout(() => this.scrollToFirstErrorElement(), 500);
        return;
      }
      const data = {
        TimoCom: this.TimoCom,
        LoadFox: this.LoadFox,
        Trans: this.Trans,
        Cargoclix: this.Cargoclix,
        BulkExchange: this.BulkExchange,
        Sonstige: this.Sonstige,
        SonstigeText: this.SonstigeText,
        thirdPartyContainers: this.thirdPartyContainers,
        thirdPartyTrailers: this.thirdPartyTrailers,
        Others: this.Others,
        OthersText: this.OthersText,
        AGB_449: this.AGB_449,
        ADSp: this.ADSp,
        AGB_BSK: this.AGB_BSK,
        AGBremovals: this.AGBremovals,
        VBGL: this.VBGL,
        VehicleType: this.VehicleType,
        processName: this.processName,
        transportInsuranceForClients: this.transportInsuranceForClients,
        AGB: this.AGB,
        grossAnnual: this.grossAnnual,
        regionalUpTo150Percent: this.regionalUpTo150Percent,
        regionalUpTo150: this.regionalUpTo150,
        DeutschlandPercentage: this.DeutschlandPercentage,
        Deutschland: this.Deutschland,
        BorderingCountriesPercentage: this.BorderingCountriesPercentage,
        BorderingCountries: this.BorderingCountries,
        EuropePercentage: this.EuropePercentage,
        Europe: this.Europe,
        SumPercentage: this.SumPercentage,
        GeneralFreight: this.GeneralFreight,
        ChilledGoods: this.ChilledGoods,
        FrozenFood: this.FrozenFood,
        TemperatureControlled: this.TemperatureControlled,
        over100k: this.over100k,
        HighQuality: this.HighQuality,
        over300k: this.over300k,
        CEPServices: this.CEPServices,
        Bulk: this.Bulk,
        MovingGoods: this.MovingGoods,
        HeavyLift: this.HeavyLift,
        GoodsInTank: this.GoodsInTank,
        MotorVehiclesOfAllKinds: this.MotorVehiclesOfAllKinds,
        SumSpecialTypes: this.SumSpecialTypes,
        foreignUnitsPromoted: this.foreignUnitsPromoted,
        subcontractorsUsed: this.subcontractorsUsed,
        VehicleFleet: this.VehicleFleet,
        subcontractorsViaFreight: this.subcontractorsViaFreight,
        vehicleListTypes: this.vehicleListTypes,
        AGBinsuranceFile: this.AGBinsuranceFile,
        FahrzeuglisteinsuranceFile: this.FahrzeuglisteinsuranceFile,
        counrtyItems: this.counrtyItems,
      };
      // const tempTotal = this.TimoCom + this.LoadFox + this.Trans + this.Cargoclix + this.BulkExchange + this.Sonstige;
      // console.log(tempTotal);
      const priceData = {
        Gattungen:
          {
            AllgemeinesKaufmannsgutOhneKuehlgut: this.GeneralFreight,
            Kuehlgut: this.ChilledGoods,
            Gefriergut: this.FrozenFood,
            PharmazeutischeProdukte: this.TemperatureControlled,
            PharmazeutischeProdukteUeber100000: this.over100k,
            HochwertigeGueter: this.HighQuality,
            HochwertigeGueterUeber300000: this.over300k,
            KEPDienste: this.CEPServices,
            MassenSchuettgueter: this.Bulk,
            Umzugsgut: this.MovingGoods,
            Schwergut: this.HeavyLift,
            // eslint-disable-next-line @typescript-eslint/camelcase
            GueterInTank_Silos: this.GoodsInTank,
            KraftfahrzeugeAlsTransportgut: this.MotorVehiclesOfAllKinds,
          },
        Gebiete:
          {
            Regional: this.regionalUpTo150Percent,
            Deutschland: this.DeutschlandPercentage,
            AngrenzendeLaender: this.BorderingCountriesPercentage,
            Europa: this.EuropePercentage,
          },
        Laufzeit: 3,
        GesamtBruttoPraemie: this.grossAnnual,
        Zusatzbeitraege:
          {
            AnzahlContainer: this.thirdPartyContainers,
            AnzahlAnhaenger: this.thirdPartyTrailers,
          },
        CheckMindestPraemie: true,
      };
      // this.$emit('road-haulier-data-changed', data);
      this.$emit('logistics-price-changed', 'd4d50735-ec6b-476f-abe3-fb8f89e325dc', priceData, data, 'LOGISTICS_INSURANCE');
      this.$router.push({
        name: this.nextPath,
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
  computed: {
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_READY;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
    nextPath(): string {
      let result = '';
      if (this.progressBar.forwardingActivities) {
        result = 'forwarding-activities';
      } else if (this.progressBar.watchman) {
        result = 'watchman';
      } else {
        result = 'logistics-insurance-price-card';
      }
      return result;
    },
    backPath(): string {
      return 'logistics-insurance';
    },
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    if (this.formData.roadHaulierFormData) {
      this.setValues(this.formData.roadHaulierFormData);
    }
  },
});
